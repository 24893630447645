import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.corporateImages = this.props.data.images.edges.filter(edge =>
            edge.node.relativePath.includes('Header Corporate')
        );
        this.personalImages = this.props.data.images.edges.filter(edge =>
            edge.node.relativePath.includes('Header Personal Branding')
        );
        this.editorialImages = this.props.data.images.edges.filter(edge =>
            edge.node.relativePath.includes('Header Editorial')
        );
        this.state = {
            currentCorporateImageIndex: 0,
            currentPersonalImageIndex: 0,
            currentEditorialImageIndex: 0
        };
    }
    componentDidMount() {
        const initialDelay = 1000;
        const delay = 500;
        const interval = 6000;
        const imageLoop = () => {
            this.setState(prevState => ({
                currentCorporateImageIndex:
                    (prevState.currentCorporateImageIndex + 1) %
                    this.corporateImages.length
            }));
            setTimeout(() => {
                this.setState(prevState => ({
                    currentPersonalImageIndex:
                        (prevState.currentPersonalImageIndex + 1) %
                        this.personalImages.length
                }));
                setTimeout(() => {
                    this.setState(prevState => ({
                        currentEditorialImageIndex:
                            (prevState.currentEditorialImageIndex + 1) %
                            this.editorialImages.length
                    }));
                }, delay);
            }, delay);
        };
        setTimeout(() => {
            imageLoop();
            setInterval(imageLoop, interval);
        }, initialDelay);
    }

    render() {
        console.log(
            this.corporateImages,
            this.state.currentCorporateImageIndex
        );
        return (
            <Layout active="Gallery" index={true}>
                <SEO title="Home" />

                <div className="showcase">
                    {this.corporateImages ? (
                        <Link className="showcase-link" to="/corporate">
                            <div className="item">
                                {this.corporateImages.map((image, index) => (
                                    <div
                                        key={image.node.id}
                                        className={[
                                            'showcase-image',
                                            this.state
                                                .currentCorporateImageIndex ===
                                            index
                                                ? 'active'
                                                : ''
                                        ]
                                            .join(' ')
                                            .trim()}
                                    >
                                        <Img
                                            fluid={{
                                                ...image.node.childImageSharp
                                                    .fluid,
                                                sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                            }}
                                        />
                                    </div>
                                ))}
                                <Img
                                    className="showcase-image-hidden"
                                    fluid={{
                                        ...this.corporateImages[0].node
                                            .childImageSharp.fluid,
                                        sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                    }}
                                />
                            </div>
                            <div className="section-text">Corporate</div>
                        </Link>
                    ) : (
                        <></>
                    )}
                    {this.personalImages ? (
                        <Link className="showcase-link" to="/personal-branding">
                            <div className="item">
                                {this.personalImages.map((image, index) => (
                                    <div
                                        key={image.node.id}
                                        className={[
                                            'showcase-image',
                                            this.state
                                                .currentPersonalImageIndex ===
                                            index
                                                ? 'active'
                                                : ''
                                        ]
                                            .join(' ')
                                            .trim()}
                                    >
                                        <Img
                                            fluid={{
                                                ...image.node.childImageSharp
                                                    .fluid,
                                                sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                            }}
                                        />
                                    </div>
                                ))}
                                <Img
                                    className="showcase-image-hidden"
                                    fluid={{
                                        ...this.personalImages[0].node
                                            .childImageSharp.fluid,
                                        sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                    }}
                                />
                            </div>
                            <div className="section-text">
                                Personal Branding
                            </div>
                        </Link>
                    ) : (
                        <></>
                    )}
                    {this.editorialImages ? (
                        <Link className="showcase-link" to="/editorial">
                            <div className="item">
                                {this.editorialImages.map((image, index) => (
                                    <div
                                        key={image.node.id}
                                        className={[
                                            'showcase-image',
                                            this.state
                                                .currentEditorialImageIndex ===
                                            index
                                                ? 'active'
                                                : ''
                                        ]
                                            .join(' ')
                                            .trim()}
                                    >
                                        <Img
                                            fluid={{
                                                ...image.node.childImageSharp
                                                    .fluid,
                                                sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                            }}
                                        />
                                    </div>
                                ))}
                                <Img
                                    className="showcase-image-hidden"
                                    fluid={{
                                        ...this.editorialImages[0].node
                                            .childImageSharp.fluid,
                                        sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                    }}
                                />
                            </div>
                            <div className="section-text">Editorial</div>
                        </Link>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="landing-page-content">
                    <div className="landing-page-text">
                        a good headshot is the best first impression
                    </div>
                    <Link to="/contact">
                        <div className="landing-page-button">get in touch</div>
                    </Link>
                </div>
            </Layout>
        );
    }
}

export default IndexPage;

export const pageQuery = graphql`
    query {
        images: allFile(
            sort: { fields: name }
            filter: {
                relativeDirectory: {
                    in: [
                        "Header Corporate"
                        "Header Editorial"
                        "Header Personal Branding"
                    ]
                }
            }
        ) {
            edges {
                node {
                    id
                    relativePath
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
